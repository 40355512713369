import axios from "axios";
import * as Constants from "config/constants";

export default {
  getCategoriesByStoreId: (storeId, params) => {
    return axios({
      method: "GET",
      url: `${Constants.API_ENDPOINT_BASE}/stores/${storeId}/categories/`,
      params,
    });
  },
};
