import React, { useContext, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import StoreContext from "context/Store";
import classNames from "classnames";
import { useInfiniteCategoriesByStoreId } from "features/categories/hooks";
import { useInView } from "react-intersection-observer";

const CategoryNav = () => {
  const { t: trans } = useTranslation("common");
  const router = useRouter();
  const {
    query: { category: activeCategory },
  } = router;
  const StoreContextValue = useContext(StoreContext);
  const store = StoreContextValue?.store?.data ?? {};
  const [ref, inView] = useInView({ rootMargin: "40px 0px 0px 0px" });
  const {
    categories,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteCategoriesByStoreId(store?.id, {
    limit: 20,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <React.Fragment>
      <nav className="px-4 py-4 text-center">
        <ul className="link-items m-auto">
          {categories.map((category) => {
            return (
              <li
                className="link-item inline-block text-sm px-2 font-bold"
                key={category.slug}
              >
                <Link
                  href="/[category]"
                  as={`/${category.slug}`}
                  className={classNames(
                    category.slug === activeCategory
                      ? "nav-link underline text-secondary"
                      : "nav-link underline text-gray-800 font-semibold"
                  )}
                >
                  {trans([category.name], { [category.name]: category.name })}
                </Link>
              </li>
            );
          })}
          {isFetchingNextPage ? (
            <li className="link-item inline-block text-sm px-2 font-bold" key="loading-text">
              Loading...
            </li>
          ) : null}
        </ul>
        <div ref={ref} className="h-1" />
      </nav>
      <style jsx>{`
        .link-items {
          width: 90%;
        }
        .link-item a::after {
          content: " >";
        }
      `}</style>
    </React.Fragment>
  );
};

export default CategoryNav;
