export const defaultParamsGetProducts = {
  limit: 10,
  ordering: "-id",
  is_public: true,
  is_active: true
};

export const defaultParamsGetCategoryProducts = {
  limit: 10,
  ordering: "category_position",
  is_public: true,
  is_active: true
};
