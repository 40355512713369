import { useInfiniteQuery } from "@tanstack/react-query";
import AuthContext from "context/Auth";
import { useContext, useMemo } from "react";
import CategoriesService from "services/categories";

export function useInfiniteCategoriesByStoreId(storeId, params = {}) {
  const { auth } = useContext(AuthContext);
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ["categories", { storeId, ...params }],
    queryFn: ({ pageParam }) => 
      CategoriesService.getCategoriesByStoreId(
        storeId,
        {
          ...params,
          ...(pageParam || {}),
        }
      ),
    initialPageParam: 0,
    enabled: !!auth && !!storeId,
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.next) {
        const url = new URL(lastPage?.data?.next);
        const offset = url.searchParams.get("offset");
        return { offset };
      }
      return undefined;
    },
  });

  const categories = useMemo(() => {
    if (!data?.pages) return [];

    return data?.pages?.reduce((acc, page) => {
      return [...acc, ...(page?.data?.results || [])];
    }, [])
  }, [data]);

  return { categories, ...rest };
}
